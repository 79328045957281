import Header from './shared/Header';

const title = 'A Monumental Task';
const description =
  'Despite the challenges inherent to this task, the Population Project is  dedicated to recording the name of every single living person.';

const infoCards = [
  {
    title: '300,000 babies born every day',
    description:
      'Not all births are registered, which makes tracking them difficult',
    background: '/images/baby.png',
  },
  {
    title: '15% humans without identification',
    description:
      'The information that we log is collected from multitudinous sources that must be cross-checked, again and again, for accuracy',
    background: '/images/africans.jpeg',
  },
  {
    title:
      '0 countries using names in their census reports without identification',
    description:
      'Sources we gather data from may contain errors; typos are known to happen even in electoral records and graduation lists',
    background: '/images/reports.png',
  },
];

export default function TasksSection() {
  return (
    <div className="bg-neutral-50">
      <div className="container py-20 max-sm:py-10">
        <Header title={title} description={description} />
        <div className="mt-[3.25rem] grid grid-flow-row grid-cols-3 gap-7 max-sm:mt-6 max-sm:grid-flow-col max-sm:grid-cols-1 max-sm:grid-rows-3 max-sm:gap-4">
          {infoCards.map(({ title, description, background }, key) => (
            <div
              key={key}
              className="overflow-hidden rounded-xl text-white"
              style={{
                backgroundImage: `url('${background}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                aspectRatio: '1/1',
              }}
            >
              <div
                className="h-full p-8 max-sm:p-6"
                style={{
                  background:
                    'linear-gradient(rgba(12, 39, 36, 0.9), rgba(12, 39, 36, 0))',
                }}
              >
                <div className="text-heading-6-semibold text-white">
                  {title}
                </div>
                <div className="mt-2 text-label-2-medium text-white">
                  {description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
