import { useSelector } from 'react-redux';
import {
  GlobeSimpleIcon,
  MapMarkerIcon,
  PencilIcon,
  TextBoxOutlineIcon,
} from '../../assets/new/icons';
import { BlurredButtonLink } from '../../components/button/blurred-button';
import { SearchComponent } from '../../components/search';
import { useRecordedPopulation } from '../../hooks/useRecordedPopulation';
import type { HomePageProps } from '../../pages';
import type { RootState } from '../../store';
import type { ILinkProps } from '../../types/types';
import { formatNumber } from '../../utils/format';
import { getWorldPopulation } from '../../utils/getWorldPopulation';

type NavLinkOptions = {
  payload: string;
  icon: JSX.Element;
} & ILinkProps;

export default function HeroSection(
  props: Pick<HomePageProps, 'countries' | 'WORdata'>
) {
  const { country } = useSelector((state: RootState) => state.search);
  const { countryData } = useSelector((state: RootState) => state.country);

  const recordedPopulation = useRecordedPopulation({
    selectedCountry: country,
    lastRun: props.WORdata?.statistics?.stats_run?.last_run,
    worldLivingPopulation: props.WORdata?.living,
    worldPendingPopulation: props.WORdata?.pending,
    countryData: countryData,
    loadingText: props.WORdata ? '' : ' ',
  });

  const worldPopulation = getWorldPopulation();

  const recordedPercentage = (
    (Number(recordedPopulation?.replaceAll(',', '')) / worldPopulation) *
    100
  ).toFixed(2);

  return (
    <div
      className=""
      style={{
        backgroundImage: `url(images/main-page-hero-section-bg.jpeg)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div
        className="flex flex-col items-center"
        style={{
          background:
            'rgba(6, 21, 20, 0.2) linear-gradient(rgba(12, 39, 36, 0.4), rgba(12, 39, 36, 0))',
        }}
      >
        <div className="container flex flex-col items-center py-[6.25rem] max-sm:py-10">
          <div className="mb-8 flex flex-col items-center">
            <div className="mb-2 flex flex-row items-stretch">
              <div
                className="mr-4 text-heading-2-bold tabular-nums text-white max-sm:text-heading-4-bold"
                suppressHydrationWarning
              >
                {recordedPopulation}
              </div>
              <div
                className="flex items-center rounded-[2.5rem] bg-green-800 px-4 text-heading-3-medium tabular-nums text-green-50 max-sm:text-heading-6-semibold"
                suppressHydrationWarning
              >
                {recordedPercentage}%
              </div>
            </div>
            <div className="text-center text-title-2-regular text-white max-sm:max-w-80 max-sm:text-label-1-regular">
              Total population recorded out of{' '}
              <span
                className="font-semibold tabular-nums"
                suppressHydrationWarning
              >
                {formatNumber(worldPopulation)}
              </span>{' '}
              people born
            </div>
          </div>

          <SearchComponent
            countries={props.countries}
            className="mb-8"
            buttonPayload={'Search a human'}
            uniqueId="hero"
          />

          <div className="mb-7 flex flex-row space-x-2 max-sm:grid max-sm:grid-cols-2 max-sm:gap-2 max-sm:space-x-0">
            {/* <NavLink */}
            {/*  payload={'Explore map'} */}
            {/*  href="/world" */}
            {/*  icon={ */}
            {/*    <GlobeSimpleIcon */}
            {/*      className="fill-green-100" */}
            {/*      width="1.25rem" */}
            {/*      height="1.25rem" */}
            {/*    /> */}
            {/*  } */}
            {/* /> */}
            <NavLink
              payload={'Find a country'}
              href="/world"
              icon={
                <MapMarkerIcon
                  className="fill-green-100"
                  width="1.25rem"
                  height="1.25rem"
                />
              }
            />
            <NavLink
              payload={'Create a record'}
              href="/create-human"
              icon={
                <PencilIcon
                  className="fill-green-100"
                  width="1.25rem"
                  height="1.25rem"
                />
              }
            />
            <NavLink
              payload={'Submit a list'}
              href="/volunteer/submit"
              icon={
                <TextBoxOutlineIcon
                  className="fill-green-100"
                  width="1.25rem"
                  height="1.25rem"
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const NavLink = ({ payload, icon, ...props }: NavLinkOptions) => (
  <BlurredButtonLink {...props}>
    <div className="flex h-full flex-row items-center justify-between max-sm:justify-start">
      {icon}
      <div className="ml-2 text-label-2-medium text-white">{payload}</div>
    </div>
  </BlurredButtonLink>
);
