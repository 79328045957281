/* eslint-disable @typescript-eslint/no-explicit-any */

import { kv } from '@vercel/kv';
import type { NextPageContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getCountryStaticProps } from '../actions/countryActions';
import { fetchNewsletterPosts } from '../actions/newsletterActions';
import { searchForAllCountriesStaticProps } from '../actions/searchActions';
import { worldDomainName } from '../config';
import HomePage from '../page-components/index';
import type { Article } from '../types/article';
import type { PaginatedResponse } from '../types/common';
import type { DataWorldChartTypes } from '../types/types';
import type { WorldData } from '../types/world';

export type HomePageProps = {
  countries: DataWorldChartTypes[];
  WORdata?: WorldData;
  articles: PaginatedResponse<Article[]> | null;
};

export default HomePage;

export const getServerSideProps = async ({
  locale,
}: NextPageContext): Promise<{ props: HomePageProps }> => {
  let countries: DataWorldChartTypes[] = [];
  let WORdata;
  let articles: PaginatedResponse<Article[]> | undefined;

  const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds
  const now = Date.now();
  const translations = await serverSideTranslations(locale ?? 'en', [
    'common',
    'navbar',
    'new-homepage',
    'new-navbar',
    'new-footer',
    'homepage',
  ]);
  try {
    // Get cached data and timestamp from KV
    const cachedCountries = await kv.get(
      `${process.env.KV_ENV ?? ''}-countries`
    );
    const cachedWORdata = await kv.get(`${process.env.KV_ENV ?? ''}-WORdata`);
    const cachedTimestamp = await kv.get(
      `${process.env.KV_ENV ?? ''}-cachedTimestamp`
    );

    // If cache exists and is still valid (not older than 15 minutes), use the cached data
    if (
      cachedCountries &&
      cachedWORdata &&
      cachedTimestamp &&
      now - Number(cachedTimestamp) < fifteenMinutes
    ) {
      countries = cachedCountries as DataWorldChartTypes[];
      WORdata = cachedWORdata;
    } else {
      // Fetch new data if cache is missing or stale
      [countries, WORdata] = await Promise.all([
        searchForAllCountriesStaticProps(),
        getCountryStaticProps('WOR', worldDomainName), // Replace with the actual domain name
      ]);

      // Save the fetched and processed data to KV along with the current timestamp
      await Promise.all([
        kv.set(
          `${process.env.KV_ENV ?? ''}-countries`,
          JSON.stringify(countries)
        ),
        kv.set(
          `${process.env.KV_ENV ?? ''}-WORdata`,
          JSON.stringify({
            ...WORdata,
            living: WORdata?.population?.living || null,
            iso3: 'WOR',
            last_run: WORdata?.statistics?.stats_run?.last_run || null,
            pending: WORdata?.population?.pending || null,
          })
        ),
        kv.set(`${process.env.KV_ENV ?? ''}-cachedTimestamp`, now.toString()), // Save the current timestamp
      ]);
    }

    articles = await fetchNewsletterPosts({
      page: 1,
      size: 128,
    });
  } catch (error) {
    console.error(error);
  }

  return {
    props: {
      ...(translations && typeof translations === 'object' ? translations : {}),
      countries: countries ?? [],
      articles: articles ?? null,
      WORdata: {
        ...WORdata,
        living: WORdata?.population?.living || null,
        iso3: 'WOR',
        last_run: WORdata?.statistics?.stats_run?.last_run || null,
        pending: WORdata?.population?.pending || null,
      },
    },
  };
};
