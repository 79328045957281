import type { PropsWithChildren } from 'react';
import { GreenButtonLink } from '../../components/button/green-button';
import {
  FourDSvg,
  BrightDataSvg,
  DropboxSvg,
  GoogleAdsSvg,
  CivittaSvg,
  SlackSvg,
} from './assets';
import Header from './shared/Header';

const buttonText = 'About Us';

const title = 'What is The Population Project';

const description =
  'The Population Project is a nonprofit organization whose goal is to record the full name and place and date of birth of all humans alive.';

export default function AboutSection() {
  return (
    <div className="container py-20 max-sm:py-10">
      <div className="flex flex-col items-center">
        <Header title={title} description={description} />
        <GreenButtonLink
          payload={buttonText}
          className="mt-6"
          href={'/what-is-the-population-project'}
        />
        <div className="relative mt-12 max-h-[36.25rem]  overflow-hidden rounded-[1.25rem] align-middle max-sm:mt-6">
          <img src="/images/children.png" alt="about"></img>
        </div>
        <div className="mt-8 flex flex-row flex-wrap items-center justify-center space-x-6 max-2xl:space-x-0">
          <SvgContainer link="https://dropbox.com/">
            <DropboxSvg />
          </SvgContainer>
          <SvgContainer link="https://slack.com/">
            <SlackSvg />
          </SvgContainer>
          <SvgContainer link="https://4d.com/">
            <FourDSvg />
          </SvgContainer>

          <SvgContainer link="https://objectivesystems.com/">
            <img
              src="/images/objective-systems.png"
              alt=""
              className="w-36 min-w-36"
            />
          </SvgContainer>

          <SvgContainer link="https://brightdata.com/">
            <BrightDataSvg />
          </SvgContainer>
          <SvgContainer link="https://google.com/grants/">
            <GoogleAdsSvg />
          </SvgContainer>
          <SvgContainer link="https://civitta.com/">
            <CivittaSvg />
          </SvgContainer>
          <SvgContainer link="https://forebears.io/">
            <img
              src="/images/forebears-2.png"
              alt=""
              className="w-36 min-w-36"
            />
          </SvgContainer>
        </div>
      </div>
    </div>
  );
}

const SvgContainer = (props: PropsWithChildren<unknown> & { link?: string }) =>
  props.link ? (
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex h-16 items-center px-5 max-xl:px-2"
    >
      {props.children}
    </a>
  ) : (
    <div className="flex h-16 items-center px-5 max-xl:px-2">
      {props.children}
    </div>
  );
