import { useSelector } from 'react-redux';
import { useRecordedPopulation } from '../../hooks/useRecordedPopulation';
import type { HomePageProps } from '../../pages';
import type { RootState } from '../../store';
import { formatNumber } from '../../utils/format';
import Header from './shared/Header';

const title = 'Because every life deserves to be acknowledged';

const description =
  'It is estimated that 1 billion people have no legal existence whatsoever. They cannot vote, travel, or open a bank account.';

export default function WorldMapSection(props: Pick<HomePageProps, 'WORdata'>) {
  const { country } = useSelector((state: RootState) => state.search);
  const { countryData } = useSelector((state: RootState) => state.country);

  const recordedPopulation = useRecordedPopulation({
    selectedCountry: country,
    lastRun: props.WORdata?.statistics?.stats_run?.last_run,
    worldLivingPopulation: props.WORdata?.living,
    worldPendingPopulation: props.WORdata?.pending,
    countryData: countryData,
    loadingText: props.WORdata ? '' : ' ',
  });

  const statistics = [
    {
      amount: formatNumber(recordedPopulation),
      description: 'Living people documented in our database',
    },
    {
      amount: props.WORdata?.dead ? formatNumber(props.WORdata.dead) : '-',
      description: 'Deceased people documented in our database',
    },
    {
      amount: props.WORdata?.average_age
        ? `${Math.abs(props.WORdata.average_age).toFixed(1)} yrs`
        : '-',
      description: 'Average age of a human documented in our database',
    },
    {
      amount: props.WORdata?.completion_index
        ? `${props.WORdata.completion_index.toFixed(1)}%`
        : '-',
      description: 'Information we have on average for each human',
    },
    {
      amount: props.WORdata?.certainty_index
        ? props.WORdata?.certainty_index?.toFixed(1)
        : '-',
      description: 'Average certainty index for each human',
    },
  ];

  return (
    <div className="bg-green-50">
      <div className="bg-worldMap bg-[center_top_90%] bg-no-repeat max-sm:bg-[center_top_80%]">
        <div className="container py-20 max-sm:py-10">
          <Header
            title={title}
            description={description}
            className="mb-40 max-sm:mb-10"
          />
          <div className="flex flex-col items-center">
            <div className="mb-[8.75rem] flex flex-row justify-center space-x-7 max-sm:mb-0 max-sm:flex-col max-sm:justify-items-center max-sm:space-x-0 max-sm:space-y-10">
              {statistics.map(({ amount, description }, key) => (
                <div
                  key={key}
                  className="flex flex-col items-center *:text-center"
                >
                  <div className="mb-2 text-heading-5-semibold tabular-nums text-neutral-950 max-sm:text-heading-6-semibold">
                    {amount}
                  </div>
                  <div className="max-w-48 text-label-2-medium text-neutral-600 max-sm:max-w-72 max-sm:text-label-2-regular">
                    {description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
