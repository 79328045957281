import Header from './shared/Header';

const title = 'F.A.Q';

const cards = [
  {
    question: 'Why we need volunteers?',
    answer:
      'Calling the Population Project an ambitious initiative might be the  understatement of the year. We need to identify up to 8 billion people  in countries with highly different linguistic structures and record-keeping traditions. Simply put, we need your time and enthusiasm to gather as many records  from as many corners of the world as possible. It means scouring the  internet, looking for long lists of names, ideally from official  sources.',
  },
  {
    question: 'Why is the website’s counter sometimes down?',
    answer:
      'It is the star of the Population Project: our counter of living humans  is never idle for long. Most of the time, it’s ticking up as we process  new public lists. Sometimes thought, it’s inching down. Before  explaining how it is possible, it’s worth remembering that the  Population Project’s counter reflects the number of LIVING humans in the  database. But we also host 152 ',
  },
  {
    question:
      'Is there a way to stay informed about The Population Project’s activities?',
    answer:
      'Information on newsletters, social media, or other communication channels.',
  },
  {
    question: 'How can I contact The Population Project for more information?',
    answer: 'Contact details and methods for reaching out.',
  },
  {
    question: 'How can I contribute to The Population Project?',
    answer:
      'The world map depicted on the home page should give you an idea of the  countries where we need the most help. The ideal list is long, official,  and contains some notion of birth year. Counting your family members, friends, schoolmates, and colleagues, you  probably know a few hundred people. It’s up to you to see them all on  the Population Project! Look',
  },
  {
    question: 'What are the goals of The Population Project?',
    answer:
      'Simply put, because every life deserves to be acknowledged. It  is estimated that one billion people - 15% of the world’s population -  has no legal existence whatsoever. They cannot vote, travel, open a bank  account. The Population Project might not reverse the legal plight of  these people but it can give them that doesn’t cost much but means a  lot: dignity.',
  },
];

export default function QuestionsSection() {
  return (
    <div className="container py-20 max-sm:py-10">
      <Header title={title} />
      <div className="mt-[3.25rem] grid grid-cols-3 gap-7 max-sm:mt-6 max-sm:grid-cols-none max-sm:gap-4">
        {cards.map(({ question, answer }, key) => (
          <div key={key} className="rounded-2xl border border-gray-200 p-8">
            <div className="mb-3 text-title-1-semibold text-gray-950 max-sm:text-title-2-semibold">
              {question}
            </div>
            <div className="text-label-2-regular leading-[1.375rem] text-gray-600">
              {answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
