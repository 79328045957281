import { useTranslation } from 'next-i18next';
import { PageLayout } from 'src/components/layout/pageLayout.component';
import type { HomePageProps } from '../../pages';
import AboutSection from './AboutSection';
import ArticlesSection from './ArticlesSection';
import HeroSection from './HeroSection';
import QuestionsSection from './QuestionsSection';
import TasksSection from './TasksSection';
import WorldMapSection from './WorldMapSection';

export default function HomePage(props: Readonly<HomePageProps>) {
  const { t } = useTranslation([
    'common',
    'new-homepage',
    'new-navbar',
    'new-footer',
    'homepage',
  ]);

  return (
    <PageLayout
      fullWidth
      title={t('homepage:seo.title')}
      description={t('homepage:seo.description')}
      countries={[]}
      className="[&&]:pb-0"
    >
      <HeroSection countries={props.countries} WORdata={props.WORdata} />
      <ArticlesSection articles={props.articles} />
      <WorldMapSection WORdata={props.WORdata} />
      <AboutSection />
      <TasksSection />
      <QuestionsSection />
    </PageLayout>
  );
}
