import debounce from 'lodash/debounce';
import type { LegacyRef } from 'react';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { ArrowRightIcon, ArrowLeftIcon } from '../../assets/new/icons';
import ArticleCard from '../../components/_new/article/card';
import { GreenButtonLink } from '../../components/button/green-button';
import { TransparentButton } from '../../components/button/transparent-button';
import type { HomePageProps } from '../../pages';
import type { Article } from '../../types/article';
import type { PaginatedResponse } from '../../types/common';
import { cn } from '../../utils/cn';

const title = 'Latest articles';

const textButton = 'View all articles';

const showedArticles = 4;

export default function ArticlesSection({
  articles,
}: Pick<HomePageProps, 'articles'>) {
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(false);

  const checkForMobileLayout = debounce(() => {
    setIsMobileLayout(window.innerWidth < 640);
  }, 100);

  useEffect(() => {
    checkForMobileLayout();
    window.addEventListener('resize', checkForMobileLayout);

    return () => {
      window.removeEventListener('resize', checkForMobileLayout);
    };
  }, []);

  const [current, setCurrent] = useState(0);
  const sliderRef: LegacyRef<Slider> = useRef(null);

  const settings = {
    infinite: false,
    speed: 200,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrent(newIndex),
  };

  const articlesCount = articles ? articles.data.length : 0;

  return (
    <div className="py-[3.75rem] max-sm:py-10">
      <div className="container flex flex-row items-center justify-between">
        <div className="text-heading-4-semibold text-neutral-950 max-sm:text-title-1-semibold">
          {title}
        </div>
        <div className="flex space-x-3 max-sm:hidden">
          <TransparentButton
            disabled={current === 0}
            IconBefore={<ArrowLeftIcon width="1.25rem" height="1.25rem" />}
            onClick={() => {
              sliderRef?.current?.slickPrev();
            }}
          />
          <TransparentButton
            disabled={!articles || articlesCount === current + showedArticles}
            IconBefore={<ArrowRightIcon width="1.25rem" height="1.25rem" />}
            onClick={() => {
              sliderRef?.current?.slickNext();
            }}
          />
        </div>
      </div>

      {!isMobileLayout && (
        <Slider
          className={cn(
            'container [&>div]:-mx-3.5 [&>div>div>div]:!flex [&>div>div]:flex [&>div>div>div]:h-auto max-sm:hidden'
          )}
          ref={sliderRef}
          {...settings}
        >
          {articles?.data.map((article) => {
            return (
              <div
                className="flex h-full px-3.5 py-10 max-sm:min-w-56"
                key={article.slug}
              >
                <ArticleCard
                  article={article}
                  href={`/blog/${article.slug}`}
                  className="h-full"
                />
              </div>
            );
          })}
        </Slider>
      )}

      {isMobileLayout && (
        <MobileSlider articles={articles} className="sm:hidden" />
      )}

      <div className="container flex flex-row justify-center">
        <GreenButtonLink payload={textButton} href={'/blog'} />
      </div>
    </div>
  );
}

export function MobileSlider({
  articles,
  className,
}: {
  className: string;
  articles: PaginatedResponse<Article[]> | null;
}) {
  return (
    <div
      className={cn(
        'flex gap-7',
        'px-[var(--mobile-margin)] py-6',
        'overflow-x-scroll [&::-webkit-scrollbar]:hidden',
        className
      )}
    >
      {articles?.data.map((article) => {
        return (
          <ArticleCard
            key={article.slug}
            article={article}
            href={`/blog/${article.slug}`}
            className="min-w-56"
          />
        );
      })}
    </div>
  );
}
