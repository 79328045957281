type Options = {
  title: string;
  description?: string;
  className?: string;
};

export default function Header({ title, description, className }: Options) {
  return (
    <div className={`flex flex-col items-center text-center ${className}`}>
      <div className="w-full max-w-[46rem] text-heading-3-semibold text-neutral-950  max-sm:text-heading-6-semibold">
        {title}
      </div>
      {description && (
        <div className="mt-3 max-w-2xl text-title-2-regular text-neutral-600 max-sm:mt-2 max-sm:text-label-2-regular">
          {description}
        </div>
      )}
    </div>
  );
}
