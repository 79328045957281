import Link from 'next/link';
import type { PropsWithChildren } from 'react';
import type { ILinkProps } from '../../types/types';

export const BlurredButtonLink = ({
  children,
  ...props
}: PropsWithChildren<ILinkProps>) => (
  <Link
    {...props}
    className="rounded-[2.5rem] py-3 pl-4 pr-5 hover:shadow-[0_0_0_2px_theme(colors.green.400)] focus:shadow-[0_0_0_2px_theme(colors.green.400)] max-md:p-3"
    style={{
      background: 'rgba(12, 39, 36, 0.7)',
      backdropFilter: 'blur(8px)',
    }}
  >
    {children}
  </Link>
);
